const error: any = {
    en: {
      errorPageTitle: 'This page does not exist',
      errorPageSubTitle: 'Follow this link to go back to homepage',
      errorButtonLabel: 'Click',
    },
    fr: {
      errorPageTitle: "Cette page n'existe pas",
      errorPageSubTitle: 'Suivez ce lien pour retourner vers la page principale',
      errorButtonLabel: 'Cliquez',
    },
  };
  
  export default error;
  