import styled from 'styled-components';
import { FormHelperText, MenuItem, Select as MuiSelect } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { ActivityPricesTypeEnum, listOfPositiveNumber } from '../../helpers/constants';
import { UseFieldArrayReturn } from 'react-hook-form';
import { FormBox } from 'helpers/generalStyles';

export default function PriceInput(props: { 
    name: string, 
    id: number, 
    control: any, 
    formPrices: UseFieldArrayReturn<any>,
    price: { name: string, number: number, price: number, type?: ActivityPricesTypeEnum }, 
    ticketsAlreadyInBooking: number, 
    maxCapacity: number, 
    otherTicketsInTheUpdate: number, 
    numberOfAvailablePlaces: number, 
    error: any, 
    t:any 
}) {   
    const { id, formPrices, price, ticketsAlreadyInBooking, maxCapacity, numberOfAvailablePlaces, otherTicketsInTheUpdate, error, t } = props 
    const bookingsPeopleOptions = listOfPositiveNumber(maxCapacity + 1).map((number) => {
        // we disable if hasAValueWhichLeadToExceedCurrentAvaibility is true
        const hasAValueWhichLeadToExceedCurrentAvaibility = otherTicketsInTheUpdate + number - ticketsAlreadyInBooking > numberOfAvailablePlaces;
        return { value: String(number), label: `${number}`, disabled: hasAValueWhichLeadToExceedCurrentAvaibility } 
    });

    return (
        <PricesDivItem>
            <div style={{ flex: 1, paddingLeft: "30px", fontWeight: "bold" }}>{price.type ? t(price.type) : price.name}</div>
            <PricesDivItemRight> 
                <PricesDivNumberInput>
                    <FormBox>
                        <MuiSelect
                            value={price.number}
                            displayEmpty
                            variant="outlined" 
                            error={Boolean(error)}
                            renderValue={(selected) => {
                                const selectedLabel = bookingsPeopleOptions.find((item) => item.value === String(selected))?.label;
                                return Number(selectedLabel);
                            }}
                            onChange={(event) => {
                                const value = event.target.value;
                                formPrices.update(id, { ...price, number: Number(value) })
                            }}
                        >   
                            {bookingsPeopleOptions.map((option) => (
                                <MenuItem disabled={option.disabled} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </MuiSelect>
                        <FormHelperText>{error ? t(error?.type) : ''}</FormHelperText>
                    </FormBox>
                </PricesDivNumberInput>
                <CloseIcon style={{ color: "#004643", width: "18px", fontWeight: 800 }} />
                <div style={{ color: "#004643", fontWeight: 800 }}>{price.price} €</div>
            </PricesDivItemRight>
        </PricesDivItem>
    )
}

const PricesDivItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background-color: #E1F9F3;
    width: 450px;
    border-radius: 30px;
    height: 70px;
    -webkit-box-shadow: 5px 5px 11px -3px #BABABA; 
    box-shadow: 5px 5px 11px -3px #666666;
`;

const PricesDivItemRight = styled.div`
    display: flex;
    flex: 2;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`;

const PricesDivNumberInput = styled.div`
    width: 120px;
    // border: solid #4FD1C5 1px;
    padding: 3px;
    border-radius: 30px;
`;
