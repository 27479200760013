const login: any = {
  en: {
    // login page
    loginTitle: 'Login',
    email: 'Email',
    password: 'Password',
    forgotPwd: 'Forgot your password ?',
    loginButton: 'Login',
    noAccount: 'I do not have an account, I subscribe',
    sentenceWithTos1: 'By creating your account, you accept the',
    sentenceWithTos2: 'Privacy Policy',
    sentenceWithTos3: 'and the',
    sentenceWithTos4: 'Terms of Use',
    sentenceWithTos5: 'of Hopleisure.',
    forgotPasswordTitle: 'Reset password',
    goBackToLogin: 'Go back to login page',
    resetPwdButton: 'Reset',
    noAccountSubscribe: 'I do not have an account, I subscribe',
    registerTitle: 'Create my account',
    passwordVerification: 'Password verification',
    registerButton: 'Register',
    passwordResetTitle: 'Update my password',
    passwordResetButton: 'Update',
    // form errors
    invalid_string: 'Invalid email',
    too_small: 'Must contain at least 8 character(s)',
    password_mismatch: 'Passwords do not match',
    // api errors
    BAD_CREDENTIALS: 'Wrong password or email',
    LOGIN_EMAIL_NOT_CONFIRMED: "Please go validate your account through the confirmation email you should have received",
    NOT_FOUND: 'No account',
    INTERNAL_SERVOR_ERROR: 'Internal server error, please contact us',
    ALREADY_EXISTING_USER: 'User already exist in our database, please login or reset your password if you lost it',
    // success
    resetPasswordEmailSent: 'Go check your emails, you should have received an email explaining how to reset your password', 
    resetPasswordSuccessMessage: "Password successfully updated, we are redirecting you to the login page",
    signupSuccessMessage: 'Signup successful! Go check your emails to validate your account and then you will be able to login'
  },
  fr: {
    loginTitle: 'Connexion',
    email: 'Email',
    password: 'Mot de passe',
    forgotPwd: 'Mot de passe oublié ?',
    loginButton: 'Connexion',
    noAccount: 'Je n’ai pas de compte, je m’inscris',
    sentenceWithTos1: 'En créant votre compte, vous acceptez la',
    sentenceWithTos2: 'Politique de confidentialité ',
    sentenceWithTos3: 'et les',
    sentenceWithTos4: "Conditions d'utilisation",
    sentenceWithTos5: 'de Hopleisure.',
    forgotPasswordTitle: 'Mot de passe oublié',
    goBackToLogin: 'Retour à l’écran de connexion',
    resetPwdButton: 'Envoyer le lien de réinitialisation',
    noAccountSubscribe: "Je n'ai pas de compte, je m'inscris",
    registerTitle: 'Créer mon compte',
    passwordVerification: 'Vérification mot de passe',
    registerButton: 'Créer mon compte',
    passwordResetTitle: 'Changer mon mot de passe',
    passwordResetButton: 'Changer',
    // form errors
    invalid_string: 'Email invalide',
    too_small: 'Il faut au moins 8 caractères',
    password_mismatch: 'Les mots de passe ne correspondent pas',
    // api errors
    BAD_CREDENTIALS: 'Mauvais mot de passe ou mauvais email',
    LOGIN_EMAIL_NOT_CONFIRMED: "Veuillez aller valider votre compte grâce à l'email de confirmation",
    NOT_FOUND: 'Pas de compte trouvé',
    INTERNAL_SERVOR_ERROR: 'Erreur interne, veuillez nous contacter',
    ALREADY_EXISTING_USER: 'Un utilisateur avec cet email existe déja, veuillez vous connecter ou bien faire mot de passe oublié',
    // success
    resetPasswordEmailSent: 'Un lien de réinitialisation vous a été envoyé par mail, veuillez consulter votre boite mail. Le lien vous parviendra sous 5 à 10 min maximum.',
    resetPasswordSuccessMessage: "Mot de passe mis à jour, nous vous redirigeons vers la page de connexion",
    signupSuccessMessage: 'Inscription réussie ! Veuillez consulter vos mails, vous devriez avoir reçu un email afin de valider votre compte, vous pourrez ensuite vous connecter'
  },
};

export default login;
