const discounts: any = {
  en: {
    title: "Discounts",
    noActivities: "Please create an activity first to have access to the creation of discounts.",
    associateActivities: "Associate activities",
    noDiscounts: "No discounts",
    errors: {
      codeAlreadyExists: "This code already exists",
      INTERNAL_SERVOR_ERROR: "internal server error, please contact us",
      DISCOUNT_CODE_ALREADY_EXISTS: "A discount with this code has already been created in the past",
    },
    success: {
      create: "Discount created successfully",
      delete: "Discount deleted successfully",
    },
    form: {
      create: {
        title: "Create discount coupon",
        name: "Coupon name",
        discountPercentage: "Discount (%)",
        code: "Activation code",
        associateActivities: "Associate activities",
        button: "Create discount coupon",
      },
    },
    removeDiscount: {
      title: "Are you sure you want to delete this discount ?",
      button: "Delete",
      cancel: "Cancel",
    },
    'Expected string, received null' : 'Expected string, received null',
    'Required' : 'Required',
    'Number must be greater than or equal to 0': 'Number must be greater than or equal to 0'
  },
  fr: {
    title: "Promotions",
    noActivities: "Veuillez d'abord créer une activité afin d'avoir accès à la création de promotions.",
    associateActivities: "Activités associées",
    noDiscounts: "Aucune promotion",
    errors: {
      codeAlreadyExists: "Ce code existe déjà",
      INTERNAL_SERVOR_ERROR: "Erreur interne, veuillez nous contacter",
      DISCOUNT_CODE_ALREADY_EXISTS: "Une promotion avec ce code a déjà été créé par le passé",
    },
    success: {
      create: "Promotion créée avec succès",
      delete: "Promotion supprimée avec succès",
    },
    form: {
      create: {
        title: "Créer un coupon de réduction",
        name: "Nom du coupon",
        discountPercentage: "Réduction (%)",
        code: "Code d'activation",
        associateActivities: "Activités associées",
        button: "Créer le coupon de réduction",
      },
    },
    removeDiscount: {
      title: "Êtes-vous sûr de vouloir supprimer cette promotion ?",
      button: "Supprimer",
      cancel: "Annuler",
    },
    'Expected string, received null' : 'Le champ est obligatoire',
    'Required' : 'Le champ est obligatoire',
    'Number must be greater than or equal to 0': 'Veuillez entrer une valeur positive'
  },
};

export default discounts;
