import React from "react";
import { SelectChangeEvent } from "@mui/material";
import { InputMultiSelect } from "components/formInputs/Inputs";


interface SelectActivitiesProps {
  selectedActivityIds: string[];
  activities: any[];
  handleSelectChange: (event: SelectChangeEvent<(string | number)[]>) => void
  errors?: any;
  t: any;
  id?: string
}

const SelectActivities = ({
  selectedActivityIds,
  activities,
  handleSelectChange,
  errors,
  t,
  id
}: SelectActivitiesProps) => {
  return (
    <InputMultiSelect
      value={selectedActivityIds.map((id) => id.toString())}
      onChange={handleSelectChange}
      label={t("form.create.associateActivities")}
      id={id || "activity_id"}
      placeholder={t("associateActivities")}
      name={"activity_id"}
      options={activities.map((activity) => ({
        value: activity.id.toString(),
        label: activity.informations.name,
      }))}
      required
      error={errors && errors.activity_id}
      t={t}
    />
  );
};

export default SelectActivities;
