import { Alert } from "@mui/material";
import { Message } from "helpers/helpers";
import { useEffect, useState, useRef, Dispatch, SetStateAction } from "react";

export default function AlertMessage(props: {
  alertMessage: string;
  setAlertMessage?: Dispatch<SetStateAction<Message>>;
  type?: "error" | "success";
  t: any;
}) {
  const { alertMessage, setAlertMessage, type, t } = props;
  const [visible, setVisible] = useState(true);
  const [leftPosition, setLeftPosition] = useState("50%");
  const alertRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setVisible(true);
    setLeftPosition(alertRef.current ? `calc(50% - ${alertRef.current.offsetWidth / 2}px)` : "50%");
    const timer = setTimeout(() => {
      setVisible(false);
      if (setAlertMessage) {
        setAlertMessage({ type: "success", message: "" });
      }
    }, 5000); 

    return () => clearTimeout(timer);
  }, [alertMessage]);


  return (
    <div
      ref={alertRef}
      style={{
        position: "fixed",
        top: 20,
        left: leftPosition,
        zIndex: 1000,
        visibility: visible ? "visible" : "hidden",
      }}
    >
      {alertMessage && <Alert severity={type || "error"}>{t(alertMessage)}</Alert>}
    </div>
  );
}
