import styled from "styled-components";
import { Select, UncontrolledChip } from "../../formInputs/Inputs";
import { DateCalendar } from "@mui/x-date-pickers"
import { Button } from "@mui/material";
import { FormErrorHelper, ThirdTitleText } from "../../../helpers/generalStyles";
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Activity, ActivityAvaibilities, Avaibility } from "../../../backend/api/activities";
import { BookingFormBody, BookingFormSection } from "../bookingsStyles";
import { UseFormReturn } from "react-hook-form";
import { BookingFormValidator } from "./bookingFormValidator";
import { SpecialSlotTypeEnum } from "helpers/constants";
import moment from "moment";

export default function BookingFormGroup1(props: { form: UseFormReturn<BookingFormValidator>, formSlots: any, activities: Activity[], setAvaibility: any, selectedDay: moment.Moment, setSelectedDay: any, setSelectedMonth: any, monthAvaibilities: ActivityAvaibilities, setPage?: any, t: any }) {
    const { form, formSlots, activities, setAvaibility, selectedDay, setSelectedDay, setSelectedMonth, monthAvaibilities, setPage, t } = props
    const isInCarroussel = setPage ? true : false;
    const { control, formState: { errors }, trigger } = form
    const { fields, replace, remove } = formSlots
    const activitiesOptions = activities.map((activity) => { return { value: String(activity.id), label: activity.informations.name } });
    const pageBlockingErrors: any = ['activityId', 'date', 'slots']

    const onClickNextPage = async () => {
        // trigger function allow to launch validation only on fields we pass in parameters of the function
        const isValid = await trigger(pageBlockingErrors);
        if (isValid) setPage(2)
        else console.log({errors})
    }

    const isAValidAvailability = (date: string, availability: Avaibility) => {
      const isASpecialSlotWhichRenderSlotUnavailable = 
        availability.specialSlotType === SpecialSlotTypeEnum.MARK_AS_FULL ||
        availability.specialSlotType === SpecialSlotTypeEnum.UNAVAILABLE
      return (
        moment(`${date} ${availability.startTime}`, 'YYYY-MM-DD HH:mm') >= moment() && !isASpecialSlotWhichRenderSlotUnavailable
      )
    }

    const enabledDays = monthAvaibilities.filter((item) => item.avaibilities
      .some((avaibility) => isAValidAvailability(item.date, avaibility)))
      .map((item) => item.date)

    const shouldDisableDate = (date: moment.Moment) => {
      return !enabledDays.includes(date.format('YYYY-MM-DD'));
    };

    const selectedDayAvailabilities = 
      monthAvaibilities.find((item) => item.date === selectedDay.format('YYYY-MM-DD'))

    const availaibilities = selectedDayAvailabilities?.avaibilities.filter((item) => isAValidAvailability(selectedDayAvailabilities.date, item)) || []

    const handleDateChange = (date: moment.Moment | null) => {
      if (date) {    
        setSelectedDay(date);
        form.setValue('date', date);
      }
    }

    const handleMonthChange = (date: moment.Moment | null) => {
      if (date) {    
        setSelectedMonth(date.month())
      }
    }

    return (
        <BookingFormBody>
          <ThirdTitleText>{t("activityAndSlot").toUpperCase()}</ThirdTitleText>
          <BookingFormSection style={{ justifyContent: "flex-start" }}>
            <Select id={"activityId"} name={"activityId"} placeholder={t("activity")} options={activitiesOptions} required control={control} error={Boolean(errors?.activityId?.message) ? t('invalid_type') : ''} t={t}/>
            <div style={{ display: "flex", width: "100%" }}>
                <DateCalendar
                  sx={{ margin: "0", width: "100%" }}
                  shouldDisableDate={shouldDisableDate}
                  value={selectedDay}
                  onChange={handleDateChange} // Ajout de la fonction de changement de date
                  onMonthChange={handleMonthChange}
                />
                <AvaibilitiesSection style={{ overflowY: "scroll", width: "55%" }}>
                  { errors.slots ? <FormErrorHelper>{t('slotsAreMandatory')}</FormErrorHelper> : null}
                  {availaibilities.length > 0 ? (
                    availaibilities.map((avaibility: { startTime: string, endTime: string, maxCapacity: number, numberOfUnitBooked: number}) => {
                    const existingAvaibility: any = fields.find((field: any) => field.from === avaibility.startTime && field.to === avaibility.endTime)
                    const isSelected = Boolean(existingAvaibility);
                    const isAvailablePlaces = avaibility.maxCapacity - avaibility.numberOfUnitBooked > 0;
                    return (
                    <div>
                      <UncontrolledChip 
                          isSelected={isSelected}
                          disabled={!isAvailablePlaces}
                          onChange={() => {
                            if(!isSelected) {
                              replace({ from: avaibility.startTime, to: avaibility.endTime})
                              setAvaibility(avaibility)
                            } else {
                              remove(existingAvaibility.id)
                              setAvaibility(undefined)
                            }
                          }} 
                          label={`${avaibility.startTime} | ${ isAvailablePlaces ? avaibility.maxCapacity - avaibility.numberOfUnitBooked : 0}`} 
                          icon={ isAvailablePlaces ? <PersonIcon /> : <PersonOffIcon />}
                          t={t} 
                      />
                    </div>
                  )})
                  ) : <div style={{ color: 'lightgrey', fontWeight: 300 }}>
                    {t('notAvailabledActivity')}
                  </div>
                }
                </AvaibilitiesSection>
          </div>
          </BookingFormSection>
          <Button style={{ display: isInCarroussel ? "initial" : "none", position: "absolute", bottom: 25, right: 50 }} onClick={onClickNextPage} color="info" variant="contained" type="button">{t('next')}</Button>
        </BookingFormBody>
    )
  }

const AvaibilitiesSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 300px;
    gap: 20px;
    width: 100%;
`;