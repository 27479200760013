import axios from "axios";
import { AuthResponse } from "types/auth";
import { z } from "zod";
// zod objects here should be the same than the ones in the backend !


// login
const loginInput = z.object({
    body: z.object({
      email: z.string().email(),
      password: z.string(),
    }),
  });
type LoginInput = z.infer<typeof loginInput>;

export async function login(data: LoginInput['body']) {
    const result: AuthResponse = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signin`, { email: data.email, password: data.password})
    return result;
}


// sendResetPasswordLink
const sendResetPasswordLinkInput = z.object({
    query: z.object({
      email: z.string().email(),
    }),
  });
type SendResetPasswordLinkInput = z.infer<typeof sendResetPasswordLinkInput>;

export async function sendResetPasswordLink(data: SendResetPasswordLinkInput['query']) {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/auth/sendResetPasswordLink?email=${data.email}`)
    return result;
}


// updatePassword
const updatePasswordInput = z.object({
    body: z
      .object({
        password: z.string(),
        passwordVerification: z.string(),
        supabaseAccessToken: z.string(),
        supabaseRefreshToken: z.string(),
      })
      .refine((body: any) => body.password === body.passwordVerification, {
        message: 'password and passwordVerification mismatch',
      }),
  });
type UpdatePasswordInput = z.infer<typeof updatePasswordInput>;

export async function updatePassword(data: UpdatePasswordInput['body']) {
    const result = await axios.put(`${process.env.REACT_APP_API_URL}/auth/resetPassword`, { 
        password: data.password,
        passwordVerification: data.passwordVerification,
        supabaseAccessToken: data.supabaseAccessToken,
        supabaseRefreshToken: data.supabaseRefreshToken,
    })
    return result;
}


// signup
const signupInput = z.object({
    body: z
      .object({
        email: z.string().email(),
        password: z.string(),
        passwordVerification: z.string(),
      })
      .refine((body: any) => body.password === body.passwordVerification, {
        message: 'password and passwordVerification mismatch',
      }),
  });

type SignupInput = z.infer<typeof signupInput>;

export async function signup(data: SignupInput['body']) {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, { email: data.email, password: data.password, passwordVerification: data.passwordVerification })
    return result;
}