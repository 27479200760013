import { BookingStatusEnum, SpecialSlotTypeEnum } from "../../../helpers/constants";

export const isBookingCanBeUpdated = (bookingStatus: BookingStatusEnum): boolean => {
    return ![BookingStatusEnum.CANCELED, BookingStatusEnum.PENDING, BookingStatusEnum.DONE, BookingStatusEnum.NO_SHOW].includes(bookingStatus);
}

export const isBookingStatusCanBeUpdated = (bookingStatus: BookingStatusEnum): boolean => {
    return ![BookingStatusEnum.CANCELED, BookingStatusEnum.PENDING, BookingStatusEnum.DONE].includes(bookingStatus);
}

export const statusColorMapping: any = {
    [BookingStatusEnum.DONE]: "#004643",
    [BookingStatusEnum.BOOKED]: "#4FD1C5",
    [BookingStatusEnum.PENDING]: "lightgrey",
    [BookingStatusEnum.CANCELED]: "#fff6d7",
    [BookingStatusEnum.NO_SHOW]: "#fff6d7",
}

export const isSlotBookable = (specialSlotType: SpecialSlotTypeEnum | null | undefined): boolean => {
    return specialSlotType === undefined || specialSlotType === null
}