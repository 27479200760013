import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';

export default function LanguageSelector() {
    const [language, setLanguage] = useState('fr')
    const { t, i18n } = useTranslation('auth');
    
    useEffect(() => {
        i18n.changeLanguage(language)
      }, [i18n, language])

    return (        
        <Select
            labelId="language-select"
            id="demo-simple-select-standard"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            label="language"
            style={{ position: 'absolute', top: 25, right: 25 }}
        >
            <MenuItem value={'fr'}>fr</MenuItem>
            <MenuItem value={'en'}>en</MenuItem>
        </Select>
    )
}
