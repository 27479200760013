import { Button } from "@mui/material";
import { FourthTitleText, GreenBoxWithTitle, SecondaryTitleText, ThirdTitleText } from "../../../helpers/generalStyles";
import { Activity } from "../../../backend/api/activities";
import { BookingFormBody, BookingFormSection } from "../bookingsStyles";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { BookingFormValidator } from "./bookingFormValidator";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

export default function BookingFormGroup3(props: { form: UseFormReturn<BookingFormValidator>, activity: Activity, setPage?: any, t: any }) {
    const { form, activity, setPage, t } = props    
    const isInCarroussel = setPage !== undefined ? true : false;
    const { handleSubmit, control, formState: { errors }, watch } = form
    const values = form.getValues()
    const price = values.prices.reduce((sum: any, curr: any) => sum += Number(curr.number) * Number(curr.price), 0)

    const onPreviousPage = () => {
      setPage(2)
    }

    return (
      <BookingFormBody style={{ justifyContent: "center"}}>
        <ThirdTitleText>{t("priceRecap").toUpperCase()}</ThirdTitleText>  
        <BookingFormSection style={{ justifyContent: 'space-around', borderBottom: "solid 1px lightgrey" }}>
          <GreenBoxWithTitle> {activity.informations.name}</GreenBoxWithTitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <EventAvailableIcon />
            <FourthTitleText>{`${values.date.format('DD-MMMM-YYYY')} | ${values.slots[0]?.from} - ${values.slots[0]?.to}`}</FourthTitleText>
          </div>
        </BookingFormSection>
        <BookingFormSection style={{ flex: 3, borderBottom: "solid 1px lightgrey" }}>
          {values.prices.map((price: any) => (
            <FourthTitleText style={{ textAlign: "left" }}>{`${price.number} x  ${price.name}`}</FourthTitleText>
          ))}
          <SecondaryTitleText style={{justifySelf: "flex-end"}}>{t('totalPrice')}</SecondaryTitleText> <ThirdTitleText>{`${price.toFixed(2)} €`}</ThirdTitleText>
        </BookingFormSection>
        <Button style={{ display: isInCarroussel ? "initial" : "none", position: "absolute", bottom: 25, left: 50 }} color="info" variant="contained" onClick={onPreviousPage} type="button">{t('previous')}</Button>
        <Button style={{ display: isInCarroussel ? "initial" : "none", position: "absolute", bottom: 25, right: 50 }} color="info" variant="contained" type="submit">{t('confirm')}</Button>
      </BookingFormBody>
    )
  }