import { Button } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import styled from "styled-components";
import { MainPageCard, FormDiv, SecondaryTitleText, ThirdTitleText } from "../../../helpers/generalStyles";
import { UncontrolledSwitch } from "../../formInputs/Inputs";
import Locker from "../Locker";
import { ActivityChannels, upsertActivity as apiUpsertActivity } from "../../../backend/api/activities";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../..";
import { Platform, getAllPlatforms as apiGetAllPlatforms} from "../../../backend/api/platforms";
import { Message, pick } from "../../../helpers/helpers";
import AlertMessage from '../../general/AlertMessage';


export function ChannelsForm(props: {inputData: ActivityChannels, activityId: number|null, isLockerOpen: boolean, setIsLockerOpen: any, navigate: any, t: any}) {
    const { inputData, activityId, isLockerOpen, setIsLockerOpen, navigate, t } = { ...props}
    const [ alertMessage, setAlertMessage] = useState<Message>({ type: "success", message: ""})
    const [platforms, setPlatforms] = useState<Platform[]>([]);
    const [activityPlatforms, setActivityPlatforms] = useState<Channel[]>([]);

    interface Channel extends Platform {
        status: string;
      }  

    useEffect(() => {
        setAlertMessage({ message: '', type: 'success'})
    }, [inputData])

    useEffect(() => {
        const activityPlatforms = platforms.map((item) => { return { 
            ...item,
            status: inputData.find((inputDataItem) => inputDataItem.platformId === item.id && inputDataItem.status === 'OPENED') ? 'OPENED' : 'CLOSED'
        }})
        setActivityPlatforms(activityPlatforms);
    }, [platforms, inputData])

    useQuery({
        queryKey: ['getAllPlatforms'],
        queryFn: apiGetAllPlatforms,
        // staleTime: 60000,
        refetchOnWindowFocus: false,
        onSuccess(data) {
            setPlatforms(data);
        },
    })

    const mutateActivity = useMutation({
        mutationFn: (data: ActivityChannels) => apiUpsertActivity({ channels: [...data ],  activityId: activityId ? String(activityId) : null }),
        onSuccess: (result: any) => {
          queryClient.invalidateQueries({ queryKey: ['getActivitiesWithDisabled'] })
          setIsLockerOpen(false)
        },
        onError: (error: any) => {
            console.log(error);
            setAlertMessage({type: 'error', message: t(error?.response?.data?.error)});
        },
    })

    const onSubmit = () => {
        const formatData = activityPlatforms.map((item) => { return { ...pick(item,['name', 'status']), platformId: item.id }  })
        mutateActivity.mutate(formatData)
    }
  
    const handleSwitchChange = (platformId: number, status: string) => {
        const newActivityPlatforms = [...activityPlatforms]
        let activityPlatformIndex = newActivityPlatforms.findIndex((item) => item.id === platformId)
        newActivityPlatforms[activityPlatformIndex] = { ...newActivityPlatforms[activityPlatformIndex],  status: status === "OPENED" ? "CLOSED" : "OPENED"}
        setActivityPlatforms(newActivityPlatforms)
    } 
    return (
        <MainPageCard>
            <Locker isOpen={isLockerOpen} onClick={() => setIsLockerOpen(!isLockerOpen)}/>
            <SecondaryTitleText>{t('sellChannelsSubTitle').toUpperCase()}</SecondaryTitleText>
            <FormDiv style={{ minWidth: '200px'}}>
                <AlertMessage alertMessage={alertMessage.message} type={alertMessage.type} t={t} />
                <div>
                    <ChannelsSwitchDiv>
                        {
                            activityPlatforms.map((channel, index) => (
                                <UncontrolledSwitch key={index} label={channel.name} isActive={channel.status === "OPENED"} onChange={() => handleSwitchChange(channel.id, channel.status)} disabled={!isLockerOpen} t={t} />
                            ))
                        }
                    </ChannelsSwitchDiv>  
                </div>
                <Button disabled={!isLockerOpen || mutateActivity.isLoading} color="info" variant="contained" type="button" onClick={onSubmit}>{t('save')}</Button>
            </FormDiv>
        </MainPageCard>
    )
  }

  const ChannelsSwitchDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  `;
