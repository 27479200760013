import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { z } from 'zod';
import HopleisureSide from '../components/auth/HopleisureSide';
import { AuthTitle, HopleisureLink } from '../helpers/generalStyles';
import { signup as apiSignup} from '../backend/api/auth';
import LanguageSelector from '../components/general/LanguageSelector';
import AlertMessage from '../components/general/AlertMessage';
import { Message } from '../helpers/helpers';
import { VisibilityOff, Visibility } from '@mui/icons-material';

export default function Register() {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const [ alertMessage, setAlertMessage] = useState<Message>({ type: "success", message: ""})

  return (
    <Page>
      <Container>
        <HopleisureSide />
        <FormCard>
          <AlertMessage alertMessage={alertMessage.message} setAlertMessage={setAlertMessage} type={alertMessage.type} t={t} />
          <LanguageSelector />
          {SignupForm(setAlertMessage, navigate, t)}
        </FormCard>
      </Container>
    </Page>
  );
}

function SignupForm(setAlertMessage: any, navigate: any, t:any) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordVerification, setShowPasswordVerification] = useState(false);
  // should match the types in API reset route
  const signupFormInput = z.object({
    email: z.string().email(),
    password: z.string().min(8),
    passwordVerification: z.string().min(8)
  }).refine((body: any) => body.password === body.passwordVerification, {
    path: ["passwordVerification"],
    message: t('password_mismatch'),
  })

  type SignupFormInput = z.infer<typeof signupFormInput>;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(signupFormInput),
    defaultValues: {
      email: '',
      password: '',
      passwordVerification: '',
    }
  })
  
  const mutateSignup = useMutation({
    mutationFn: async (data: SignupFormInput) => apiSignup(data),
    onSuccess: (result) => {
      setAlertMessage({type: 'success', message: t('signupSuccessMessage')});
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({type: 'error', message: t(error?.response?.data?.error)});
    },
  })
  const onSubmit: SubmitHandler<any> = (data: SignupFormInput) => {
    mutateSignup.mutate({ email: data.email, password: data.password, passwordVerification: data.passwordVerification })
  }

  return (
    <FormDiv onSubmit={handleSubmit(onSubmit)}>
      <AuthTitle>{t("registerTitle").toUpperCase()}</AuthTitle>
      <Controller
        name="email"
        control={control}
        render={({ field }) => 
          <TextField 
            fullWidth={true} 
            id="email" 
            label={t("email")}
            variant="standard" 
            error={Boolean(errors.email)}
            helperText={errors.email ? t(errors?.email.type) : ''}
            {...field} 
          /> 
        }
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => 
          <TextField 
            fullWidth={true} 
            id="password" 
            label={t("password")}
            type={showPassword ? "text" : "password"}
            variant="standard" 
            error={Boolean(errors.password)}
            helperText={errors.password ? t(errors?.password.type) : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...field} 
          /> 
        }
      />
      <Controller
        name="passwordVerification"
        control={control}
        render={({ field }) => 
          <TextField 
            fullWidth={true} 
            id="passwordVerification" 
            label={t("passwordVerification")}
            type={showPasswordVerification ? "text" : "password"}
            variant="standard"
            error={Boolean(errors.passwordVerification)}
            helperText={errors.passwordVerification ? t(errors?.passwordVerification.type) === 'custom' ? errors?.passwordVerification.message : t(errors?.passwordVerification.type) : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPasswordVerification(!showPasswordVerification)}
                    edge="end"
                  >
                    {showPasswordVerification ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...field} 
          /> 
        }
      />
      <HopleisureLink  style={{ width: '100%', textAlign: 'right' }} onClick={() => navigate('/login')}>{t('goBackToLogin')}</HopleisureLink> 
      <Button fullWidth={true} variant="contained" type="submit">{t('registerButton')}</Button>
    </FormDiv>
  )
}

const Page = styled.div`
    display: flex;
    height: 100vh;
    position: relative;
    background-color: #054643;
  `;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    padding: 30px;
    gap: 30px;
  `;

  const FormCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex:3;
  background-color: white;
  height: 100%;
  border-radius: 20px;
`;

const FormDiv = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  min-width: 400px;
  gap: 30px;
`;