import { zodResolver } from "@hookform/resolvers/zod";
import { Button, ImageList, ImageListItem } from "@mui/material";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { useMutation } from "react-query";
import { MainPageCard, FormDiv, SecondaryTitleText, ThirdTitleText } from "../../../helpers/generalStyles";
import { InputText, Select, InputFile, ControlledSwitch } from "../../formInputs/Inputs";
import { ActivityInformations, activityInformations, upsertActivity as apiUpsertActivity } from '../../../backend/api/activities';
import GoogleMaps from "../../formInputs/AddressAutocomplete";
import Locker from "../Locker";
import { queryClient } from "../../..";
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from "react";
import AlertMessage from '../../general/AlertMessage';
import { activityCategories, activitySlotTypes } from "../../../helpers/constants";
import { Message } from "../../../helpers/helpers";

export function InformationsForm(props: { inputData: ActivityInformations, activityId: number|null, isLockerOpen: boolean, setIsLockerOpen: any, navigate: any, t: any }) {
    const { inputData, activityId, isLockerOpen, setIsLockerOpen, navigate, t } = props
    const [ alertMessage, setAlertMessage] = useState<Message>({ type: "success", message: ""})
    const activityCategoriesOptions = Object.entries(activityCategories).map((obj) => { return { value: obj[0], label: t(obj[1]) } });
    const activitySlotTypesOptions = Object.entries(activitySlotTypes).map((obj) => { return { value: obj[0], label: t(obj[1]) } });
    useEffect(() => {
      setAlertMessage({ message: "", type: "success"})
    }, [inputData])
  
    const mutateActivity = useMutation({
      mutationFn: (data: ActivityInformations) => apiUpsertActivity({ informations: {...data },  activityId: activityId ? String(activityId) : null}),
      onSuccess: (result: any) => {
        queryClient.invalidateQueries({ queryKey: ['getActivitiesWithDisabled'] })
        setIsLockerOpen(false)
      },
      onError: (error: any) => {
        console.log(error);
        setAlertMessage({type: 'error', message: t(error?.response?.data?.error)});
      },
    })

    const {
      control,
      handleSubmit,
      clearErrors,
      watch,
      formState: { errors },
    } = useForm({
      resolver: zodResolver(activityInformations),
      values: inputData
    })

    const onSubmit: SubmitHandler<any> = (data: ActivityInformations) => {
      mutateActivity.mutate(data)
    }

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'images',
  });

    return (
      <MainPageCard>
        <Locker isOpen={isLockerOpen} onClick={() => setIsLockerOpen(!isLockerOpen)}/>
        <SecondaryTitleText>{t('informations').toUpperCase()}</SecondaryTitleText>
        {/* cause FormDiv have minWidth: 400px and it's too much for here */}
        <FormDiv  style={{ minWidth: '200px'}} onSubmit={handleSubmit(onSubmit)}>
          <AlertMessage alertMessage={alertMessage.message} setAlertMessage={setAlertMessage} type={alertMessage.type} t={t} />
          <ThirdTitleText style={{ textAlign: 'left'}}>{t("commercialDescription").toUpperCase()}</ThirdTitleText>
          <InputText id={"name"} name={"name"} label={t("activityName")} required disabled={!isLockerOpen} control={control} error={errors["name"]?.message} t={t}/>
          <Select id={"category"} name={"category"} label={t("category")} options={activityCategoriesOptions} required disabled={!isLockerOpen} control={control} error={errors["category"]?.message} t={t}/>
          <InputText id={"description"} name={"description"}  label={t("description")} required disabled={!isLockerOpen} multiLine={true} control={control} error={errors["description"]?.message} t={t}/>
          <InputFile label={t("images")} required disabled={!isLockerOpen} onAddFile={append} error={""} t={t}/>
          <ImageList sx={{ width: "100%"}} cols={3} rowHeight={164}>
            {fields.map((image, id) => (
                <ImageListItem key={id}>
                <img
                    srcSet={`${image.url}`}
                    src={`${image.url}`}
                    alt={image.title}
                    loading="lazy"
                />
                  <DeleteIcon style={{ color: !isLockerOpen ? "#D3D3D3" : "#4FD1C5", cursor: 'pointer', position: 'absolute', right: 0 }}  onClick={() => !isLockerOpen ? console.log('locker clos') : remove(id) }/>
                </ImageListItem>
            ))}
          </ImageList>
          <InputText id={"slotDuration"} name={"slotDuration"} label={t("slotDuration")} required disabled={!isLockerOpen} inputType="number" control={control} error={errors["slotDuration"]?.message} t={t}/>
          <InputText id={"maxCapacity"} name={"maxCapacity"} label={t("maxCapacity")} required disabled={!isLockerOpen} inputType={'number'} control={control} error={errors["maxCapacity"]?.message} t={t}/>
          <GoogleMaps name={"address"} label={t("address")} control={control} required disabled={!isLockerOpen} t={t} />
          <InputText id={"phone"} name={"phone"} label={t("phone")} required disabled={!isLockerOpen} control={control} error={errors["phone"]?.message} t={t}/>
          <InputText id={"email"} name={"email"} label={t("email")} required disabled={!isLockerOpen} control={control} error={errors["email"]?.message} t={t}/>
          <InputText id={"cancelation"} name={"cancelation"} label={t("cancelation")} required disabled={!isLockerOpen} control={control} error={errors["cancelation"]?.message} t={t}/>
          <ControlledSwitch id={'isEnabled'} name={'isEnabled'} control={control} label={t("isEnabled")} disabled={!isLockerOpen} t={t} />
          <Button disabled={!isLockerOpen || mutateActivity.isLoading} color="info" variant="contained" type="submit">{t('save')}</Button>
        </FormDiv>
      </MainPageCard>
    )
  }
  