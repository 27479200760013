const activities: any = {
  en: {
    myActivitiesTitle: 'My activities',
    informationsSubTitle: 'Informations',
    commercialDescription: 'Commercial description',
    activityName: 'Activity name',
    category: 'Category',
    // categories
    arcade: "Arcade",
    bowling: "Bowling",
    laserGame: "Laser game",
    paintBall: "Paint ball",
    escapeGame: "Escape game",
    spa: "Spa",
    wellBeing: "Well-being",
    vrSimulation: "VR simulation",
    karting: "Karting",
    cruises: "Cruise",
    indoorGames: "Indoor games",
    accrobranche: "Accrobranche",
    hikingNature: "Hiking", 
    outdoorGames: "Outdoor games",
    guidedTour: "Guided tour",
    extremSport: "Extreme sport",
    boatTrip: "Boat tour",
    parachuteJump: "Parachute jump",
    creativeWorkshop: "Creative workshop",
    cookingAndLessons: "Cooking lesson",
    museumsAndExhibitions: "Museums and exhibitions",
    sportAndFitness: "Sport and fitness",

    description: 'Description',
    images: 'Images',
    slotDuration: 'Slot duration (in minutes)',
    maxCapacity: 'Max capacity',
    address: 'Address',
    phone: 'Phone',
    email: 'Email',
    cancelation: 'Cancellation policy',
    isEnabled: "Enable the activity",
    save: 'Save',

    hoursSubTitle: 'Hours',
    noPermanentActivity: 'No permanent activity',
    start: 'Start',
    end: 'End',

    pricesSubTitle: 'Prices',
    priceType: 'Price type',
    price: 'Price',
    DEFAULT: 'Standard price',
    SENIOR: 'Child price',
    CHILD: 'Senior price',
    optionnalPriceTypeSubTitle: '* Recommended for external platforms',
    mandatoryPriceTypeSubTitle: '* Mandatory to broadcast activity on platforms',

    sellChannelsSubTitle: 'Sell channels',
    general: 'General',
    specialized: 'Specialized',

    // form errors
    invalid_string: 'Invalid email',
    too_small: 'Mandatory field',
    'String must contain at least 2 character(s)': 'String must contain at least 2 character(s)',
    'String must contain at least 3 character(s)': 'String must contain at least 3 character(s)',
    'String must contain at least 5 character(s)': 'String must contain at least 5 character(s)',
    'String must contain at least 10 character(s)': 'String must contain at least 10 character(s)',
    'Expected string, received null' : 'Expected string, received null',
    'Expected number, received null' : 'Expected number, received null',
    'Invalid email': 'Invalid email',
    'Number must be greater than or equal to 1': 'Number must be greater than or equal to 1',
    'Number must be greater than or equal to 0': 'Number must be greater than or equal to 0',

    // api errors
    HOURS_WITH_OVERLAPED: "Some hours are overlaping on the same day of your activity",
    HOURS_WITH_FROM_BIGGER_THAN_TO: "You have an End bigger or equal to a Start on your activity hours",
    NEED_TO_FILL_INFORMATIONS_FIRST: "You need to save information about your activity first before adding these additional information",
    NOT_FOUND: 'Data not found',
    INTERNAL_SERVOR_ERROR: 'Internal server error, please contact us'
  },
  fr: {
    myActivitiesTitle: 'Mes activités',
    informationsSubTitle: 'Informations',
    commercialDescription: 'Description commerciale',
    activityName: "Nom activité",
    category: 'Catégorie',
    // categories
    arcade: "Arcade",
    bowling: "Bowling",
    laserGame: "Laser game",
    paintBall: "Paint ball",
    escapeGame: "Escape game",
    spa: "Spa",
    wellBeing: "Bien-être",
    vrSimulation: "Simulation VR",
    karting: "Karting",
    cruises: "Croisière",
    indoorGames: "Jeux d'intérieur",
    accrobranche: "Accrobranche",
    hikingNature: "Randonnée", 
    outdoorGames: "Jeux d'extérieur",
    guidedTour: "Visite guidée",
    extremSport: "Sport extrême",
    boatTrip: "Tour de bateau",
    parachuteJump: "Saut en parachute",
    creativeWorkshop: "Atelier créatif",
    cookingAndLessons: "Leçon de cuisine",
    museumsAndExhibitions: "Musée et expositions",
    sportAndFitness: "Sport et fitness",

    description: 'Description',
    images: 'Images',
    slotDuration: 'Durée de créneau (en minutes)',
    maxCapacity: 'Capacité',
    address: 'Adresse',
    phone: 'Téléphone',
    email: 'Email',
    cancelation: "Politique d'annulation",
    isEnabled: "Activer l'activité",
    save: 'Sauvegarder',

    hoursSubTitle: 'Horaires',
    noPermanentActivity: 'Activité temporaire / événement ponctuel',
    start: 'Début',
    end: 'Fin',

    pricesSubTitle: 'Tarifs',
    priceType: 'Type de prix',
    price: 'Prix',
    DEFAULT: 'Tarif Plein',
    SENIOR: 'Tarif Senior',
    CHILD: 'Tarif Enfant',
    optionnalPriceTypeSubTitle: '* Vivement conseillé pour les plateformes et billetteries',
    mandatoryPriceTypeSubTitle: '* Obligatoire pour les plateformes et billetteries',

    sellChannelsSubTitle: 'Canaux de vente',
    general: 'Généraux',
    specialized: 'Spécialisés',

    // form errors
    invalid_string: 'Email invalide',
    too_small: 'Le champ est obligatoire',
    'String must contain at least 2 character(s)': 'Le champ doit contenir au moins 2 caractères',
    'String must contain at least 3 character(s)': 'Le champ doit contenir au moins 3 caractères',
    'String must contain at least 5 character(s)': 'Le champ doit contenir au moins 5 caractères',
    'String must contain at least 10 character(s)': 'Le champ doit contenir au moins 10 caractères',
    'Expected string, received null' : 'Le champ est obligatoire',
    'Expected number, received null' : 'Le champ est obligatoire',
    'Invalid email': 'Email invalide',
    'Number must be greater than or equal to 1': 'Veuillez entrer une valeur supérieure ou égale à 1',
    'Number must be greater than or equal to 0': 'Veuillez entrer une valeur positive',

    // api errors
    HOURS_WITH_OVERLAPED: "Certains de vos créneaux se chevauchent sur un jour",
    HOURS_WITH_FROM_BIGGER_THAN_TO: "Attention, un de vos créneaux a un horaire de début postérieur à son horaire de fin",
    NEED_TO_FILL_INFORMATIONS_FIRST: "Vous devez d'abord renseigner les informations sur votre activité avant de pouvoir modifier ces informations",
    NOT_FOUND: 'Données non trouvées',
    INTERNAL_SERVOR_ERROR: 'Erreur interne du serveur, veuillez nous contacter'
  },
};

export default activities;
