import { z } from "zod";
import { Trans } from "react-i18next";

export const numberablePositiveString = z.string().refine(
  (value: string) => {
    return value !== '' && Number.isFinite(Number(value)) && Number(value) >= 0;
  },
  {
    message: 'Value must be a positive number',
  }
);

export const numberableStrictlyPositiveString = z.string().refine(
  (value: string) => {
    return value !== '' && Number.isFinite(Number(value)) && Number(value) > 0;
  },
  {
    message: 'Value must be a positive number',
  }
);

const yyyymmddRegex = /^\d{4}-\d{2}-\d{2}$/;
export const dateableString = z
  .string()
  .refine((value: string) => yyyymmddRegex.test(value), {
    message: 'field must be in the YYYY-MM-DD format',
});

const yyyymmddhhmmRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
export const dateableWithTimeString = z
  .string()
  .refine((value: string) => yyyymmddhhmmRegex.test(value), {
    message: 'field must be in the YYYY-MM-DD HH:mm format',
  });

const hhmmRegex = /^\d{2}:\d{2}$/;
export const timeString = z
  .string()
  .refine((value: string) => hhmmRegex.test(value), {
    message: 'field must be in the HH:mm format',
  });

export function getAuthorizedConfig(method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE') {
  const accessToken = localStorage.getItem("accessToken")
  const config = {
      method: method,
      headers: { 
          'Authorization': `Bearer ${accessToken}`
      },
      url: '',
      data: {}
  }
  return config;
}

export const pick = (obj: any, arr: any[]): any =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => arr.includes(k)));

export const omit = (obj: any, arr: any[]): any =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => !arr.includes(k)));

export interface Message {
  type: 'success' | 'error',
  message: string,
}

export const snakeToCamelList = (inputList: any[]): any => {
  return inputList.map(item => snakeToCamelObject(item));
};

export function snakeToCamelObject(obj: Record<string, any>): any {
  const camelObj: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    const camelKey = key.replace(/_([a-z])/g, (_, char) => char.toUpperCase());
    camelObj[camelKey] = value;
  }

  return camelObj;
}

export function snakeToCamelString (str: string): string {
  return str.replace(/_([a-z])/g, (_, char) => char.toUpperCase());
}

// #TODO: explain this function in comment
export const checkOverlap = (
  start1: moment.Moment,
  end1: moment.Moment,
  start2: moment.Moment,
  end2: moment.Moment
) => {
  return (
    start1.isBetween(start2, end2, undefined, '[)') ||
    end1.isBetween(start2, end2, undefined, '(]') ||
    start2.isBetween(start1, end1, undefined, '[)') ||
    end2.isBetween(start1, end1, undefined, '(]')
  );
};

export const applyStyleToTranslation = (translationElem: { key: string, params: any, link?: string}, namespace: string) => 
  !translationElem.key ? <br/> :
  <Trans
    i18nKey={`${namespace}:${translationElem.key}`}
    components={{
      bold: <strong />,
      urlLink: <a style={{ fontWeight: "bold", color: "black"}} href={translationElem?.link} target="_blank" rel="noreferrer"/>,
      br: <br/>
    }}
    values={translationElem.params}
  />


