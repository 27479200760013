import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navigation/Navbar';
import AlertMessage from '../components/general/AlertMessage';
import { useEffect, useMemo, useState } from 'react';
import { Message, checkOverlap } from '../helpers/helpers';
import MainTitle from '../components/general/MainTitle';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import '../helpers/css/myCalendar.css';
import moment from 'moment';
import 'moment/locale/fr';
import { MainPageCard } from '../helpers/generalStyles';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { UncontrolledSelectWithCheckBoxes } from '../components/formInputs/Inputs';
import { useQuery } from 'react-query';
import { getAllActivitiesAvaibilities } from '../backend/api/activities';
import { EventData, NewBooking } from '../components/agenda/types';
import { AgendaEventUpdateModal } from '../components/agenda/mainModals/AgendaEventUpdateModal';
import { AgendaEventChangeEventStatusModal } from '../components/agenda/mainModals/AgendaEventChangeEventStatusModal';
import AgendaEvent from '../components/agenda/AgendaEvent';
import AgendaEventBookingAddUpdateModal from '../components/agenda/mainModals/AgendaEventBookingAddUpdateModal';

const enum CalendarViewEnum {
  DAY = 'day',
  WEEK = 'week'
}

const enum CalendarMomentOfDayEnum {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING'
}

export default function Agenda() {
  const { t } = useTranslation('agenda');
  const navigate = useNavigate();
  const [events, setEvents] = useState<EventData[]>([]);
  const [filteredEvents, setFilteredEvents] = useState(events)
  const [selectedEventForUpdate, setSelectedEventForUpdate] = useState<EventData|null>(null)
  const [selectedEventForChangeStatus, setSelectedEventForChangeStatus] = useState<EventData|null>(null)
  const [selectedBooking, setSelectedBooking] = useState<NewBooking|null>(null)

  // for agenda Header
  const [view, setView] = useState<CalendarViewEnum>(CalendarViewEnum.WEEK)
  const [date, setDate] = useState(moment())
  const [selectedMoment, setSelectedMoment] = useState<CalendarMomentOfDayEnum[]>([])
  const [selectedActivities, setSelectedActivities] = useState<string[]>([])
  const [ alertMessage, setAlertMessage] = useState<Message>({ type: "success", message: ""})
  const [openCancelOrBlockSlotsModal, setOpenCancelOrBlockSlotsModal] = useState(false)

  useEffect(() => {
    const filteredEventsTmp = events.filter((item: any) => {
      if (selectedActivities.length === 0) return filterEventBySelectedMoment(item);
      else return item.data && selectedActivities.includes(item.data?.title) && filterEventBySelectedMoment(item);
    })
    setFilteredEvents(filteredEventsTmp)
  }, [events, selectedActivities, selectedMoment])

  const filterEventBySelectedMoment = (event: EventData) => {
    if (selectedMoment.length === 0) return true
    const selectedMomentTmp = selectedMoment[0]
    const momentStartDate = moment(event.start)
    const momentEndDate = moment(event.end)
    let startMomentOfDay = moment(event.start);
    let endMomentOfDay = moment(event.end);
    // the day chose is a random date, no matter what date we chose for react big calendar library, what is important is the hours in the date
    if (selectedMomentTmp === CalendarMomentOfDayEnum.MORNING) {
      startMomentOfDay.set('hours', 6)
      startMomentOfDay.set('minutes', 0)
      endMomentOfDay.set('hours', 13)
      endMomentOfDay.set('minutes', 0)
    }
    if (selectedMomentTmp === CalendarMomentOfDayEnum.AFTERNOON) {
      startMomentOfDay.set('hours', 12)
      startMomentOfDay.set('minutes', 0)
      endMomentOfDay.set('hours', 19)
      endMomentOfDay.set('minutes', 0)
    }
    if (selectedMomentTmp === CalendarMomentOfDayEnum.EVENING) {
      startMomentOfDay.set('hours', 17)
      startMomentOfDay.set('minutes', 0)
      endMomentOfDay.set('hours', 23)
      endMomentOfDay.set('minutes', 59)
    }
    return checkOverlap(momentStartDate, momentEndDate, startMomentOfDay, endMomentOfDay)
  }

  // agenda init
  const localizer = momentLocalizer(moment)
  let from = date.format('YYYY-MM-DD');
  let to = date.format('YYYY-MM-DD');

  if (view === CalendarViewEnum.WEEK) {
    from = moment(date).startOf('W').format('YYYY-MM-DD');
    to = moment(date).endOf('W').format('YYYY-MM-DD');
  } else if ( view === CalendarViewEnum.DAY) {
    from = date.format('YYYY-MM-DD');
    to = date.format('YYYY-MM-DD');
  }

  useQuery({
    queryKey: ['getActivitiesAvaibilities', from, to],
    queryFn: () => getAllActivitiesAvaibilities(from, to),
    // staleTime: 60000,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      const fmtData = data.map((item) => { return { ...item, start: new Date(item.start), end: new Date(item.end)}})
      setEvents(fmtData)
    }
  })

  // Manage the moment of days displayed in calendar
  const getMinHours = () => {
    if (selectedMoment.length === 0) return undefined
    const selectedMomentTmp = selectedMoment[0]
    // the day chose is a random date, no matter what date we chose for react big calendar library, what is important is the hours in the date
    if (selectedMomentTmp === CalendarMomentOfDayEnum.MORNING) return new Date('2024-10-20 06:00')
    if (selectedMomentTmp === CalendarMomentOfDayEnum.AFTERNOON) return new Date('2024-10-20 12:00')
    if (selectedMomentTmp === CalendarMomentOfDayEnum.EVENING) return new Date('2024-10-20 17:00')
  }

  const getMaxHours = () => {
    if (selectedMoment.length === 0) return undefined
    const selectedMomentTmp = selectedMoment[0]
    // the day chose is a random date, no matter what date we chose for react big calendar library, what is important is the hours in the date
    if (selectedMomentTmp === CalendarMomentOfDayEnum.MORNING) return new Date('2024-10-20 13:00')
    if (selectedMomentTmp === CalendarMomentOfDayEnum.AFTERNOON) return new Date('2024-10-20 19:00')
    if (selectedMomentTmp === CalendarMomentOfDayEnum.EVENING) return new Date('2024-10-20 23:59')
  }

  const minCalendarHour = useMemo(() => {
    return getMinHours()
  }, [selectedMoment])

  const maxCalendarHour = useMemo(() => {
    return getMaxHours()
  }, [selectedMoment])

  // will be used to create columns in the calendar in day view
  const resourceMap = useMemo(() => {
    // @ts-ignore
    const uniqueEventActivityTitles = [...new Set(events.map((item) => item.data.title))];
    return uniqueEventActivityTitles.map((item) => { return {
      resourceId: item, resourceTitle: item
    }})
  }, [events])

  // calendar event coloration
  const titleEventColorMapping = useMemo(() => {
    const listOfColors = [ "#fff6d7", "#fcf3f3", "#f5fcf3", "#f3f9fc"]
    const colorMapping: any = {}
    resourceMap.map((item) => item.resourceId).forEach((resource, index) => {
      colorMapping[resource] = listOfColors[index%listOfColors.length]
    })
    return colorMapping;
  }, [resourceMap])

  function CustomAgendaEvent (props: { event: any }) {
    return <AgendaEvent 
      event={props.event as EventData}
      titleEventColorMapping={titleEventColorMapping}
      setSelectedEventForUpdate={setSelectedEventForUpdate}
      setSelectedEventForChangeStatus={setSelectedEventForChangeStatus}
      setSelectedBooking={setSelectedBooking}
      navigate={navigate}
      t={t} 
    />
  } 

  return (
    <Page>
      <Navbar />
      <Container>
        <AlertMessage alertMessage={alertMessage.message} setAlertMessage={setAlertMessage} type={alertMessage.type} t={t} />
        <MainTitle title={t("agendaTitle")} />
        <MainPageCard>
          {/* We will uncomment later when we will activate the features */}
          {/* <CancelOrBlockSlotsModal open={openCancelOrBlockSlotsModal} setOpen={setOpenCancelOrBlockSlotsModal} t={t} /> */}
          { selectedEventForUpdate ? (
            <AgendaEventUpdateModal event={selectedEventForUpdate} setSelectedEvent={setSelectedEventForUpdate} setSelectedBooking={setSelectedBooking} t={t} />
          ) : null }
          { selectedEventForChangeStatus ? (
            <AgendaEventChangeEventStatusModal selectedEvent={selectedEventForChangeStatus} setSelectedEvent={setSelectedEventForChangeStatus} setAlertMessage={setAlertMessage} t={t} />
          ) : null }
          {
            selectedBooking ? (
              <AgendaEventBookingAddUpdateModal selectedBooking={selectedBooking} setSelectedBooking={setSelectedBooking} setAlertMessage={setAlertMessage} t={t} />
          ) : null }
          <AgendaHeader events={events} date={date} view={view} setDate={setDate} setView={setView} selectedMoment={selectedMoment} setSelectedMoment={setSelectedMoment} selectedActivities={selectedActivities} setSelectedActivities={setSelectedActivities} setOpenCancelOrBlockSlotsModal={setOpenCancelOrBlockSlotsModal} t={t} />
          <BigCalendar
            toolbar={false}
            localizer={localizer}
            events={filteredEvents}
            startAccessor="start"
            endAccessor="end"
            style={{  maxHeight: "730px", paddingLeft: "30px", paddingRight: "30px" }}
            dayLayoutAlgorithm={"no-overlap"}
            views={[CalendarViewEnum.WEEK, CalendarViewEnum.DAY]}
            resourceIdAccessor={view === CalendarViewEnum.DAY ? "resourceId" : undefined}
            resources={view === CalendarViewEnum.DAY ? resourceMap : undefined}
            resourceTitleAccessor={view === CalendarViewEnum.DAY ? "resourceTitle" : undefined}
            step={30}
            view={view}
            date={date.toDate()}
            min={minCalendarHour}
            max={maxCalendarHour}
            components={{
              event: CustomAgendaEvent,
            }}        
          />
        </MainPageCard>
      </Container>
    </Page>
  );
}

function AgendaHeader(props: { events: EventData[], date: moment.Moment, view: CalendarViewEnum, setDate: any, setView: any, selectedMoment: any, setSelectedMoment: any, selectedActivities: any, setSelectedActivities: any, setOpenCancelOrBlockSlotsModal: any, t: any}) {
  const { events, date, view, setDate, setView, selectedMoment, setSelectedMoment, selectedActivities, setSelectedActivities, setOpenCancelOrBlockSlotsModal, t } = props;
  const momentOfDayOptions = [ { value: CalendarMomentOfDayEnum.MORNING, label: t("morning") }, { value: CalendarMomentOfDayEnum.AFTERNOON, label: t("afternoon") }, { value: CalendarMomentOfDayEnum.EVENING, label: t("evening") } ]
  // @ts-ignore
  const activitiesOptions = [...new Set(events.map((item) => item.data.title))].map((item) => { return { value: item, label: item } });
  return (
    <div style={{ display: "flex", alignItems: "center", paddingRight: "30px", paddingLeft: "30px", justifyContent: "space-between", height: '100px' }}>
      <div style={{ display: "flex", gap: "10px"}}>
        <Button variant='contained' color="info" onClick={() => {
          setDate(moment())
          setView(CalendarViewEnum.WEEK)
        }}>{t('agendaHeader.week')}</Button>
        <Button variant='contained' color="info" onClick={() => {
          setDate(moment())
          setView(CalendarViewEnum.DAY)}
        }>{t('agendaHeader.day')}</Button>
        <DateWithArrows date={date} setDate={setDate} view={view} t={t} />
      </div>
      <div  style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", height: '100px' }}>
        <UncontrolledSelectWithCheckBoxes values={selectedMoment} multiple={false} setValues={setSelectedMoment} options={momentOfDayOptions} placeholder={t('agendaHeader.momentOfDay')} error={{}} t={t}/>
        <UncontrolledSelectWithCheckBoxes values={selectedActivities} multiple={true} setValues={setSelectedActivities} options={activitiesOptions} placeholder={t('agendaHeader.activities')} error={{}} t={t}/>
        {/* We will uncomment later when we will activate the features */}
        {/* <Button fullWidth={true} variant='contained' color="info" onClick={() => setOpenCancelOrBlockSlotsModal(true)}>{t('cancelOrBlockSlots')}</Button>
        <Button variant='contained' color="info" onClick={() => setView('day')}>{t('export')}</Button>
        <Button variant='contained' color="info" onClick={() => setView('day')}>{t('print')}</Button> */}
      </div>
    </div>
  )
};

function DateWithArrows(props: { date: moment.Moment, setDate: any, view: CalendarViewEnum, t: any }) {
  const { date, setDate, view, t } = props;
  return (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", gap:"10px" }}>
      <ArrowBackIcon color="info" onClick={() => {
        const previousDate = moment(date)
        previousDate.subtract(1, view === CalendarViewEnum.DAY ? 'd' : 'w')
        setDate(previousDate)
      }} />
      <div style={{ fontWeight: 800 }}>{ view === CalendarViewEnum.DAY ? date.format('ddd DD MMMM') : `${date.startOf('week').format('DD MMMM')} - ${date.endOf('week').format('DD MMMM')}, ${t('agendaHeader.week')} ${date.week()}`}</div>
      <ArrowForwardIcon color="info" onClick={() => {
        const previousDate = moment(date)
        previousDate.add(1,  view === CalendarViewEnum.DAY ? 'd' : 'w')
        setDate(previousDate)
      }} />
    </div>
  )
}

const Page = styled.div`
    display: flex;
    height: 100%;
    position: relative;
    background-color: #E0E1E2;
  `;

const Container = styled.div`
    position: relative;
    margin: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    gap: 20px;
`;
