import axios from "axios";
import { z } from "zod";
import { dateableString, getAuthorizedConfig, numberablePositiveString } from "../../helpers/helpers";
import { ActivityPlatformStatusEnum, ActivityPricesStatusEnum, ActivityPricesTypeEnum, SpecialSlotTypeEnum } from "helpers/constants";

export const activityInformations = z.object({
    name: z.string(),
    category: z.string(),
    description: z.string(),
    images: z.array(z.object({
      url: z.string(),
      title: z.string().optional(),
      base64File: z.string().optional(),
      mimeType: z.string().optional(),
    })),
    slotDuration: z.number().min(0).max(720),
    maxCapacity: z.number().min(1),
    address: z.string(),
    phone: z.string(),
    email: z.string().email(),
    cancelation: z.string(),
    isEnabled: z.boolean(),
})
export type ActivityInformations = z.infer<typeof activityInformations>;


const slot = z.object({
    to: z.string().min(5),
    from: z.string().min(5),
  });
  
const day = z.object({
        slots: z.array(slot),
    });

export const activityHours = z.object({
  dates: z.object({
    from: dateableString,
    to: dateableString
  }).nullable().optional(),
  hours: z.object({
      "0": day,
      "1": day,
      "2": day,
      "3": day,
      "4": day,
      "5": day,
      "6": day,
  })
})

export type Slot = z.infer<typeof slot>;
export type Day = z.infer<typeof day>;
export type ActivityHours = z.infer<typeof activityHours>;


const activityPrice = z.object({
    id: z.number().optional(),
    name: z.string().min(5),
    price: z.number().min(1),
    description: z.string().nullable(),
    status: z.nativeEnum(ActivityPricesStatusEnum),
    type: z.nativeEnum(ActivityPricesTypeEnum).nullable().optional(),
  });
  

export const activityPrices = z.object({
    prices: z.array(activityPrice)
})

export type ActivityPrices = z.infer<typeof activityPrices>;

const activityChannel = z.object({
  platformId: z.number(),
  name: z.string(),
  status: z.nativeEnum(ActivityPlatformStatusEnum),
  activityExternalReference: z.string().optional().nullable(),
});


export const activityChannels = z.array(activityChannel);

export type ActivityChannels = z.infer<typeof activityChannels>;

const activity = z.object({
  id: z.number(),
  informations: activityInformations,
  hours: activityHours,
  prices: z.array(activityPrice),
  channels: z.array(activityChannel),
  outOfStock: z.boolean().optional(),
});
export type Activity = z.infer<typeof activity>;

const upsertActivityInput = z.object({
  body: z.object({
    activityId: numberablePositiveString.nullable(),
    informations: activityInformations.optional(),
    hours: activityHours.optional(),
    prices: z.array(activityPrice).optional(),
    channels: z.array(activityChannel).optional(),
  })
})

type UpsertActivityInput = z.infer<typeof upsertActivityInput>;

const avaibility = z.object({
  startTime: z.string(),
  endTime: z.string(),
  numberOfUnitBooked: z.number(),
  maxCapacity: z.number(),
  specialSlotType: z.nativeEnum(SpecialSlotTypeEnum).optional()
});

const getActivityAvaibilitiesOutput = z.array(
  z.object({
    activityId: z.number(),
    date: z.string(),
    slotDuration: z.number().min(0).max(720),
    avaibilities: z.array(avaibility),
  })
);

export type Avaibility = z.infer<typeof avaibility>;

export type ActivityAvaibilities = z.infer<
  typeof getActivityAvaibilitiesOutput
>;

// get activities
export async function getActivities(displayDisabled?: boolean): Promise<Activity[]> {
  let config = getAuthorizedConfig('GET')
  config['url'] = `${process.env.REACT_APP_API_URL}/activities${ displayDisabled === true ? '?displayDisabled=true' : ''}`
  const response = await axios.request(config)
  const result = response.data;
  return result;
}

// get activity
export async function getActivityById(activityId: number): Promise<Activity> {
  let config = getAuthorizedConfig('GET')
  config['url'] = `${process.env.REACT_APP_API_URL}/activities/activity/${activityId}`
  const response = await axios.request(config)
  const result = response.data;
  return result;
}

// post/put activity
export async function upsertActivity(data: UpsertActivityInput['body']) {
  let config: any = getAuthorizedConfig('POST');
  config['url'] = `${process.env.REACT_APP_API_URL}/activities/`
  config['data'] = data
  const response = await axios.request({
    ...config,         
    maxContentLength: 100000000,
    maxBodyLength: 1000000000
  })
  return response;
}

// getAvaibilities
export async function getAvaibilities(activityId: number, from: string, to: string): Promise<ActivityAvaibilities> {
  let config = getAuthorizedConfig('GET')
  config['url'] = `${process.env.REACT_APP_API_URL}/activities/avaibilities/${activityId}?from=${from}&to=${to}`
  const response = await axios.request(config)
  const result = response.data;
  return result;
}

const getAllActivitiesAvaibility = z.object({
  start: z.string(),
  end: z.string(),
  resourceId: z.string(),
  data: z.object({
    activityId: z.number(),
    title: z.string(),
    category: z.string(),
    maxCapacity: z.number(),
    numberOfUnitBooked: z.number(),
    specialSlotType: z
      .enum([
        SpecialSlotTypeEnum.MARK_AS_FULL,
        SpecialSlotTypeEnum.UNAVAILABLE,
      ])
      .optional(),
  }),
});

export const getAllActivitiesAvaibilitiesOutput = z.array(getAllActivitiesAvaibility);
export type GetActivitiesAvaibilitiesOutput = z.infer<
  typeof getAllActivitiesAvaibilitiesOutput
>;

// getAllActivitiesAvaibilities
export async function getAllActivitiesAvaibilities(from: string, to: string): Promise<GetActivitiesAvaibilitiesOutput> {
  let config = getAuthorizedConfig('GET')
  config['url'] = `${process.env.REACT_APP_API_URL}/activities/avaibilities?from=${from}&to=${to}`
  const response = await axios.request(config)
  const result = response.data;
  return result;
}
