import React, { useState } from "react";
import { Box, Button, CircularProgress, Modal } from "@mui/material";
import { Column, Row, Text } from "helpers/generalStyles";
import { deleteDiscount, Discount } from "backend/api/discounts";
import AlertMessage from "components/general/AlertMessage";
import { Message } from "helpers/helpers";
import { DiscountWithActivities } from "./DiscountList";
import { queryClient } from "index";

interface Props {
  discount: DiscountWithActivities;
  handleClose: () => void;
  t: any;
}

const RemoveDiscountModal = ({ discount, handleClose, t }: Props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<Message>({ type: "success", message: "" });

  const handleRemove = async () => {
    setIsLoading(true);
    await Promise.all(discount.associatedDiscountIds.map((id) => deleteDiscount(id)));

    setAlertMessage({ message: t("success.delete"), type: "success" });
    queryClient.invalidateQueries({ queryKey: ['getDiscounts'] })
    handleClose();
    setIsLoading(false);
  };

  return (
    <>
      <AlertMessage alertMessage={alertMessage.message} type={alertMessage.type} t={t} />
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {isLoading ? (
          <Column center alignCenter>
            <CircularProgress color="success" />
          </Column>
        ) : (
          <Box>
            <Text size="24px" weight={700} color="red">
              {t("removeDiscount.title")}
            </Text>
            <Row margin="30px 0 0 0" space>
              <Button variant="outlined" onClick={handleClose}>
                {t("removeDiscount.cancel")}
              </Button>
              <Button variant="contained" onClick={handleRemove}>
                {t("removeDiscount.button")}
              </Button>
            </Row>
          </Box>
        )}
      </Modal>
    </>
  );
};

export default RemoveDiscountModal;
