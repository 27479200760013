import axios from "axios";
import { z } from "zod";
import { dateableString, getAuthorizedConfig, timeString } from "../../helpers/helpers";
import { SpecialSlotTypeEnum } from "../../helpers/constants";

export const postSpecialSlotsInput = z.object({
    body: z.object({
      activityIds: z.array(z.number()),
      days: z.array(dateableString),
      fromTime: timeString,
      toTime: timeString,
      type: z.enum([
        SpecialSlotTypeEnum.UNAVAILABLE,
        SpecialSlotTypeEnum.MARK_AS_FULL,
      ]).nullable(),
    }),
  });
  
  export type PostSpecialSlotsInput = z.infer<typeof postSpecialSlotsInput>;
  
  export async function postSpecialSlots(data: PostSpecialSlotsInput['body']): Promise<any[]> {
    let config = getAuthorizedConfig('POST')
    config['url'] = `${process.env.REACT_APP_API_URL}/specialSlots`
    config['data'] = data;
    const response = await axios.request(config)
    const result = response.data;
    return result;
  }