import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Logo from '../../images/logo.svg'
import { HopleisureLink } from '../../helpers/generalStyles';

export default function HopleisureSide() {
  const { t } = useTranslation('auth');
  
  return (        
  <HopleisureDiv>
    <HopleisureLogoDiv>
      <img height="100%" width="100%" src={Logo} alt="logo" />
    </HopleisureLogoDiv>
    <HopleisureSubLogoText>
        {t('sentenceWithTos1')} <HopleisureLink href="#"> {t('sentenceWithTos2')} </HopleisureLink> {t('sentenceWithTos3')} <HopleisureLink href="#"> {t('sentenceWithTos4')} </HopleisureLink> {t('sentenceWithTos5')}
    </HopleisureSubLogoText>
    </HopleisureDiv>
  )
}

const HopleisureDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex:2;
    height: 100%;
`;

const HopleisureSubLogoText = styled.div`
    color: white;
    text-align: center;
`;

const HopleisureLogoDiv = styled.div`
    max-width: 365px;
    min-width: 10px;
    margin-top: 200px;
`;