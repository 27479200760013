import { Button, Modal } from "@mui/material";
import moment from "moment";
import { FourthTitleText, MainTitleText, ModalStyle } from "../../../helpers/generalStyles";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

export default function AgendaConfirmationModal ( props: { open: boolean, setOpen: any, momentStartDate: moment.Moment, title: string, explainations: string, cancelButton: string, validateButton: string, submitModal: any, t: any }) {
    const { open, setOpen, momentStartDate, title, cancelButton, validateButton, explainations, submitModal, t } = props;
    return (
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
              <ModalStyle>
                <MainTitleText>{title}</MainTitleText>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <EventAvailableIcon style={{color: "#4FD1C5"}} />
                  <FourthTitleText>{`${momentStartDate.format('DD-MMMM-YYYY')} | ${momentStartDate.format('HH:mm')}`}</FourthTitleText>
                </div>
                <FourthTitleText>{explainations}</FourthTitleText>
                <div style={{ display: "flex", gap: "20px" }}>
                  <Button 
                    onClick={() => {
                      setOpen(false)
                    }} 
                    color="warning" 
                    fullWidth={true} 
                    variant="contained" 
                    type="button"
                    >
                      {cancelButton}
                  </Button>
                  <Button 
                    onClick={() => {
                      submitModal()
                      setOpen(false)
                    }} 
                    fullWidth={true} 
                    variant="contained" 
                    type="button"
                  >
                    {validateButton}
                  </Button>
                </div>
              </ModalStyle>
          </Modal>
    )
  }